export const defaultProps = {
    children: [],
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    zIndex: 100,
    speed: 500,
    timingFunction: 'ease',
};
