import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from "./Store1/store";
import { Provider } from "react-redux";
import { CartProvider } from "react-use-cart";
import { GoogleOAuthProvider } from "@react-oauth/google";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="548773075616-417vc81h9agq1c7s3c72o9btiokn6dmg.apps.googleusercontent.com">
    <Provider store={store}>
      <CartProvider>
        {/* <React.StrictMode> */}
        <BrowserRouter>
          <App />
        </BrowserRouter>
        {/* </React.StrictMode> */}
      </CartProvider>
    </Provider>
  </GoogleOAuthProvider>
);
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
