import axios from "axios";
const headers = {
  'Content-Type': 'application/json',
  'accessToken': localStorage.getItem("accessToken")
}

export const httpRequest = (data, url, json) => {
  return new Promise((resolve, reject) => {
    axios.post(window.$apiBaseUrl + url, data, {
      headers: headers

    }).then(function (response) {
      if (response?.data?.status == 401) {
        localStorage.clear("accessToken");
        window.location = "/"
        // window.location = "/signIn"
      }
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    });
  });
};
