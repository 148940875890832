import { useState, useReducer } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { httpRequest } from "../../API/api";
import { setUserLoginStatus } from "../../Store1/Slices/UserSlice";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from "jwt-decode";
import "./SignIn.css";

export const SignIn = () => {
  return (
    <div className="signInGoogle">
      {/* import { useGoogleOneTapLogin } from '@react-oauth/google'; */}

      {!localStorage.accessToken &&
        <GoogleLogin
          type="icon"
          useOneTap
          auto_select
          onSuccess={credentialResponse => {
            localStorage.setItem("accessToken", credentialResponse.credential);
            window.location.href = "/";
            // const jwtDecodedData = jwtDecode(credentialResponse?.credential);
            // console.log(jwtDecodedData);
          }}
          onError={() => {
            console.log('Login Failed');
          }}
        // width="100px"

        // 
        />}
    </div>
  );
};
