import React from 'react'

export default function Footer() {
  return (
    <div className='footer'>
      <div className="footer-content">copyright reserved@2023</div>
    </div>
    // <div className="bottom-div"></div>
  )
}
// Footer.js