import { useEffect, useRef } from 'react';
export const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
        return () => clearInterval();
    }, [delay]);
};
export const normilizeFunction = (fn) => {
    return (...args) => {
        if (typeof fn === 'function') {
            fn(...args);
        }
    };
};
export const debounce = (fn, delay = 200) => {
    let timeout = null;
    return (...args) => {
        const cb = () => {
            clearTimeout(timeout);
            fn(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(cb, delay);
    };
};
